import { useRouter } from 'next/dist/client/router';
import React from 'react';

import { ClientOnly } from '../components/ClientOnly';
import { PageNotFoundErrorPage } from '../components/tailwind-ui';

export default function Custom404() {
  const { asPath } = useRouter();
  return (
    <ClientOnly>
      <PageNotFoundErrorPage url={asPath} />
    </ClientOnly>
  );
}
