import type { ReactNode } from 'react';

import { useHasMounted } from '../hooks/useHasMounted';

interface ClientOnlyProps {
  children: ReactNode;
}

export function ClientOnly(props: ClientOnlyProps) {
  const isMounted = useHasMounted();

  if (!isMounted) {
    return null;
  }

  return <>{props.children}</>;
}
